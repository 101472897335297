<template>
  <v-card>
    <v-card-title>
      Edit/Confirm GPS
      <v-dialog max-width="500" v-model="dialogs.info" eager>
        <template #activator="{ on }">
          <v-btn v-on="on" color="primary" icon>
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogs.info = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <CmsHtml value="EditGpsDescription" class="edit-description" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('update:dialog', false)"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-card-subtitle>
      <v-icon>mdi-gesture-tap</v-icon> Click map once to change photo's location
      <br />
      <v-icon>mdi-gesture-double-tap</v-icon> Click map twice to adjust bearing
    </v-card-subtitle>
    <v-alert type="error" text class="ma-3" dismissible v-if="error">{{ error }}</v-alert>
    <div style="position: relative">
      <div ref="map" class="map" style="position: relative">
        <div class="mapboxgl-control-container">
          <div class="mapboxgl-ctrl-top-left">
            <div>
              <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
                <select-map-layer v-model="style" @input="updateMapStyle" />
              </div>
            </div>
          </div>
        </div>
        <div class="confirm-label my-1">
          <div class="d-flex align-baseline justify-center flex-wrap">
            <div>
              <div class="d-flex flex-wrap align-center" v-if="showLabel(geolocation, orientation)">
                <span class="geo-label" :class="{ 'white--text': satelliteMode }"> Change : </span>
                <coordinates-and-orientation
                  :text="!satelliteMode"
                  :confirmed="geoDataConfirmed"
                  :light="
                    showLabel(updatedData.geolocation, updatedData.orientation) && geoDataConfirmed
                  "
                  class="ml-1"
                  :coordinates="geolocation"
                  :orientation="orientation"
                  small
                />
              </div>
              <div
                class="d-flex flex-wrap mt-1 align-center"
                v-if="showLabel(updatedData.geolocation, updatedData.orientation)"
              >
                <span class="geo-label" :class="{ 'white--text': satelliteMode }"> To : </span>
                <coordinates-and-orientation
                  :text="!satelliteMode"
                  small
                  class="ml-1"
                  :coordinates="updatedDataComputed.geolocation"
                  :orientation="updatedDataComputed.orientation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="select-gps"></div>
      <div>
        <div v-if="confirm">
          <div class="pa-1 text-center">If not correct, adjust the location/bearing</div>
          <v-btn class="text-none" @click="updatePhotoStation()" color="primary" tile block large>
            If confirmed, click here to continue
          </v-btn>
        </div>
        <div v-else>
          <v-btn class="text-none" @click="confirm = true" color="primary" tile block large>
            Confirm the
            {{ showLabel(updatedData.geolocation, updatedData.orientation) ? 'adjusted' : '' }}
            location/bearing
            {{ showLabel(updatedData.geolocation, updatedData.orientation) ? '' : 'shown' }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import bbox from '@turf/bbox';
import Map from '../../services/map';
import {
  geostyleParcel,
  geostyleParcelPoint,
  geostyleParcelSatellite,
  geostylePhotoStation,
  geostylePhotostationConfirmed,
  geostylePhotoStationUpdate
} from '../../utils/geostyles';
import { isSatellite, parseGeoJSON } from '../../utils/map';
import PhotoStation from '../../services/photoStation';
import bearing from '@turf/bearing';
import { point } from '@turf/helpers';
import Orientation from '../../services/orientation';
import Geolocation from '../../services/geolocation';
import config from '../../config';
import center from '@turf/center';
import Gallery from '../../services/gallery';
import { throttle } from 'throttle-debounce';
import { MAP_STYLES } from '../../utils/map';
export default {
  components: {
    // MapInfo: () => import('../components/Parcel/MapInfo.vue')
    SelectMapLayer: () => import('../../components/SelectMapLayer.vue'),
    CoordinatesAndOrientation: () => import('../CoordinatesAndOrientation.vue'),
    CmsHtml: () => import('../../components/cms/Html.vue')
  },
  props: {
    id: {
      type: String,
      required: true
    },
    formOnly: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    },
    geojson: {}
  },
  data() {
    return {
      dialogs: {
        info: false
      },
      geolocation: null,
      orientation: null,
      map: null,
      data: [],
      mapInfo: null,
      gps: null,
      newData: {
        center: {},
        line: {},
        heading: null
      },
      confirm: false,
      style: MAP_STYLES.outdoors,
      line: null,
      updatedData: {
        geolocation: { lng: '', lat: '' },
        line: { lng: '', lat: '' },
        orientation: {
          heading: ''
        }
      },
      gallery: null,
      error: '',
      recentData: null,
      geoDataConfirmed: false
    };
  },
  computed: {
    updatedDataComputed() {
      const res = {
        geolocation: this.updatedData?.geolocation?.lng
          ? this.updatedData.geolocation
          : this.geolocation,
        orientation: Orientation.isValidOrientation(this.updatedData?.orientation?.heading)
          ? this.updatedData.orientation
          : this.orientation
      };
      return res;
    },
    satelliteMode() {
      return isSatellite(this.style);
    },
    originalDataComputed() {
      return this.format(this.geolocation, this.orientation);
    },
    centerComputed() {
      if (!this.geolocation?.lng || !this.geolocation?.lat) {
        if (!this.geojson) return;
        return center(parseGeoJSON(this.geojson)).geometry.coordinates;
      }

      return [this.geolocation.lng, this.geolocation.lat];
    }
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          if (value?.geolocation && value?.geolocation?.lng) {
            this.geolocation = value.geolocation;
          }
          if (value?.orientation && Orientation.isValidOrientation(value?.orientation?.heading)) {
            this.orientation = value.orientation;
          }
          this.renderPhotoStation();
        }
      },
      immediate: true
    }
  },
  created() {
    if (!this.formOnly) {
      this.getData();
    }
  },
  mounted() {
    const map = new Map({
      accessToken: config.mapboxToken,
      center: this.centerComputed,
      container: this.$refs.map,
      zoom: 17,
      doubleClickZoom: false,
      style: this.style
    });
    map.addControls(['GeolocateControl'], '');
    this.map = map;
    map.onLoad(() => {
      this.renderParcel();
      this.renderPhotoStation();
    });
  },
  methods: {
    getData() {
      const g = new Gallery();
      g.detail(this.id).then(data => {
        this.gallery = data.data;
        this.geolocation = data.data.geolocation;
        this.orientation = data.data.orientation;
        if (Array.isArray(data.data.geoHistory)) {
          this.recentData = data.data.geoHistory.filter(
            i => i.geolocation?.lng && i?.geolocation?.lat
          )[0];
        }
        this.geoDataConfirmed = !!data.data.geoDataConfirmed;
        this.renderParcel();
        this.renderPhotoStation();
      });
    },
    updatePhotoStation() {
      const geoHistory = this.gallery?.geoHistory || [];
      this.error = '';

      const copyGeo = data => {
        const res = {
          geolocation: {
            lng: '',
            lat: '',
            accuracy: ''
          },
          orientation: {
            heading: ''
          }
        };

        try {
          res.geolocation.lng = data?.geolocation?.lng || null;
          res.geolocation.lat = data?.geolocation?.lat || null;
          res.geolocation.accuracy = data?.geolocation?.accuracy || null;
        } catch {
          //
        }
        try {
          res.orientation.heading = Orientation.isValidOrientation(data?.orientation?.heading)
            ? data?.orientation?.heading
            : null;
        } catch {
          //
        }
        return JSON.parse(JSON.stringify(res));
      };

      const oldData = copyGeo(this.gallery);
      oldData.geolocation = Geolocation.validatedData(oldData.geolocation);
      oldData.orientation = Orientation.validatedData(oldData.orientation);
      geoHistory.push({ ...oldData, timestamp: new Date(), user: this.$store.state.user.uid });

      const g = new Gallery();

      const newGeoData = {
        geolocation: {
          lng: this.updatedData?.geolocation?.lng || this.geolocation?.lng,
          lat: this.updatedData?.geolocation?.lat || this.geolocation?.lat
        },
        orientation: {
          heading: this.updatedData?.orientation?.heading || this.orientation?.heading
        }
      };

      newGeoData.geolocation = Geolocation.validatedData(newGeoData.geolocation);
      newGeoData.orientation = Geolocation.validatedData(newGeoData.orientation);

      if (this.formOnly) {
        this.$emit('input', { newGeoData, geoHistory, geoDataConfirmed: true });
        return;
      }

      g.update(this.id, { ...newGeoData, geoHistory, geoDataConfirmed: true })
        .then(() => {
          this.$emit('success', { ...newGeoData });
        })
        .catch(err => {
          this.error = err.message;
        });
    },

    showLabel(geolocation, orientation) {
      return (
        !!geolocation?.lng ||
        !!geolocation?.lat ||
        Orientation.isValidOrientation(orientation?.heading)
      );
    },
    format(geolocation, orientation) {
      const data = {
        geolocation: {
          lng: '',
          lat: ''
        },
        orientation: {
          heading: ''
        }
      };
      data.geolocation.lng = geolocation?.lng || null;
      data.geolocation.lat = geolocation?.lat || null;
      data.orientation.heading = Number(orientation?.heading).toFixed(0);
      return data;
    },
    updateMapStyle(value) {
      try {
        this.style = value;
        this.map.setStyle(value);
        setTimeout(() => {
          this.renderParcel();
        }, 100);
      } catch {
        //
      }
    },
    renderParcel() {
      if (!this.geojson) return;

      const parcelGeoJSON = { ...parseGeoJSON(this.geojson), id: 1 };

      this.map.add('parcel', 'GEOJSON', {
        geojson: Map.applyGeostyle(
          parcelGeoJSON,
          isSatellite(this.style) ? geostyleParcelSatellite : geostyleParcel
        )
      });

      setTimeout(() => {
        this.map.map.fitBounds(bbox(parcelGeoJSON), { padding: '60px', maxZoom: 17 });
      }, 100);

      let count = 0,
        timeout = null;
      const vm = this;

      this.map.map.on('click', e => {
        count += 1;
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          const { lng, lat } = e.lngLat;
          if (count > 1) {
            vm.updatedData.line = { lng, lat };
          } else {
            vm.updatedData.geolocation = { lng, lat };
          }
          count = 0;
          this.renderPhotoStation();
        }, 500);
      });
    },
    renderPhotoStation() {
      const data = {
        unconfirmed: null,
        confirmed: null,
        updated: null
      };

      if (this.geoDataConfirmed && this.recentData) {
        data.unconfirmed = { ...this.recentData, style: geostylePhotoStation, steps: 8 };
      }

      const recentData = {
        geolocation: this.geolocation,
        orientation: this.orientation,
        style: this.geoDataConfirmed ? geostylePhotoStationUpdate : geostylePhotoStation
      };

      if (this.geoDataConfirmed) {
        data.confirmed = recentData;
      } else {
        data.unconfirmed = recentData;
      }

      const newLine = this.updatedData?.line;
      const newHeading = !!newLine?.lng && !!newLine?.lat;
      const newCenter =
        !!this.updatedData?.geolocation?.lng && !!this.updatedData?.geolocation?.lat;

      if (newHeading || newCenter) {
        const newData = {
          geolocation: {
            lng: this.updatedData?.geolocation?.lng || this.geolocation?.lng,
            lat: this.updatedData?.geolocation?.lat || this.geolocation?.lat
          },
          orientation: {
            heading: this.updatedData?.orientation?.heading || this.orientation?.heading
          }
        };
        data.updated = { ...newData, style: geostylePhotoStationUpdate, steps: 32 };
        if (data.confirmed) {
          data.confirmed.steps = 12;
          data.confirmed.style = geostylePhotostationConfirmed;
        }
        if (newHeading) {
          const pointA = point([newData.geolocation.lng, newData.geolocation.lat]);
          const pointB = point([newLine.lng, newLine.lat]);
          const angle = bearing(pointA, pointB);
          newData.orientation.heading = angle;
          this.updatedData.orientation.heading = angle;
        }
      }

      const ps = new PhotoStation();
      ps.gpsOnly = true;
      ps.setGeoJSON(this.geojson);
      Object.keys(data).forEach(key => {
        if (data[key]) {
          ps.setImages([data[key]]);
          this.map.add(`photo-station-${key}`, 'GEOJSON', {
            geojson: ps.getGeoJSON(data[key].style, data[key].steps)
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.map {
  width: 100%;
  height: 50vh;
  position: relative;
}
.select-gps {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  margin-top: 100px;
}
.mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: crosshair !important;
}
.geo-label {
  min-width: 50px;
}
.confirm-label {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.edit-description {
  text-align: justify;
}
</style>